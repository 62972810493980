import React from "react"
import Link from "gatsby-link"
import "./index.less"

const categoryTitles = [
  {
    title: "Accessories",
    canonical: "accessories",
  },
  {
    title: "Bags",
    canonical: "bags",
  },
  {
    title: "Hats",
    canonical: "hats",
  },
  {
    title: "Jackets",
    canonical: "jackets",
  },
  {
    title: "Pants",
    canonical: "pants",
  },
  {
    title: "Shirts",
    canonical: "shirts",
  },
  {
    title: "Shorts",
    canonical: "shorts",
  },
  {
    title: "Shoes",
    canonical: "shoes",
  },
  {
    title: "Skate",
    canonical: "skate",
  },
  {
    title: "Sweatshirts",
    canonical: "sweatshirts",
  },
  {
    title: "T-Shirts",
    canonical: "t-shirts",
  },
  {
    title: "Tops / Sweaters",
    canonical: "tops-sweaters",
  },
]

class CategoryList extends React.Component {
  render() {
    const { categories, seasonCanonical, selectedCategory } = this.props

    return (
      <div className="category-list">
        {categories.map(category => (
          <Link
            key={category}
            to={`/${seasonCanonical}/${category}/`}
            className={selectedCategory === category ? "is-selected" : ""}
          >
            {categoryTitles.find(c => c.canonical === category).title}
          </Link>
        ))}

        <Link
          to={`/${seasonCanonical}/drops/`}
          className={selectedCategory === "drops" ? "is-selected" : ""}
        >
          Drops
        </Link>
      </div>
    )
  }
}

export default CategoryList
