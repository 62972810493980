import React from "react"
import { graphql } from "gatsby"
import Layout from "src/components/Layout"
import SEO from "src/components/seo"
import PageHeading from "src/components/PageHeading"
import CategoryList from "src/components/CategoryList"
import Link from "gatsby-link"
import "./index.less"

const DropDates = ({ data }) => {
  const season = data.season.edges[0].node
  const categories = data.categories.distinct
  const dropDates = data.dropDates.distinct

  return (
    <Layout pageName="category">
      <SEO title={season.title} image={`/images/${season.teaser.src}`} />

      <PageHeading>{season.title}</PageHeading>

      <CategoryList
        categories={categories}
        seasonCanonical={season.canonical}
        selectedCategory={"drops"}
      />

      <ol className="dropdates-list">
        {dropDates.map(dropDate => (
          <li key={dropDate}>
            <Link to={`/${season.canonical}/drops/${dropDate}`}>
              {dropDate}
            </Link>
          </li>
        ))}
      </ol>
    </Layout>
  )
}

export default DropDates

export const query = graphql`
  query DropDateQuery($season: String!) {
    season: allSeasonsJson(filter: { canonical: { eq: $season } }) {
      edges {
        node {
          title
          canonical
          teaser {
            src
          }
        }
      }
    }

    categories: allItemsJson(filter: { season: { eq: $season } }) {
      distinct(field: category)
    }

    dropDates: allItemsJson(filter: { season: { eq: $season } }) {
      distinct(field: releaseDate)
    }
  }
`
